import * as React from "react";
import { useRef, useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
import { gql } from "apollo-boost";
import { getApolloContext } from "@apollo/react-hooks";
import { useIdle } from "react-use";
import { SessionContext, InterfaceSessionContext } from "../context";
import { GA } from "../analytics/ga";
import { Session as SessionType } from "../@types/generated/types";

const HEARTBEAT = gql`
  mutation heartbeatSession(
    $page: String!
    $translatedChannelId: ID
    $translatedVideoId: ID
  ) {
    heartbeatSession(
      page: $page
      translatedChannelId: $translatedChannelId
      translatedVideoId: $translatedVideoId
    ) {
      id
      totalTime
    }
  }
`;

export const useSession = () =>
  useContext<InterfaceSessionContext>(SessionContext);

export const SessionProvider: React.FC = ({ children }) => {
  const { query, pathname } = useRouter();
  // Get the context procedurally since it may not exist yet
  const { client } = useContext(getApolloContext());
  const isIdle = useIdle(60000 * 5);
  const queryRef = useRef(query);
  const pageRef = useRef("dashboard");
  const [lastHeartbeat, setLastHeartbeat] = useState<Date | undefined>();
  const [heartbeatSuccess, setHeartbeatSuccess] = useState<boolean>(true);
  const [session, setSession] = useState<SessionType | undefined>();
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    queryRef.current = query;
  }, [query]);

  useEffect(() => {
    // videoDetails and channelDetails
    if (
      query.page &&
      !Array.isArray(query.page) &&
      ["videoDetails", "channelDetails"].includes(query.page)
    ) {
      pageRef.current = query.page;
    } else {
      if (pathname === "/studio") {
        pageRef.current = "studio";
      } else {
        pageRef.current = "dashboard";
      }
    }
  }, [query, pathname]);

  useEffect(() => {
    let ch: ReturnType<typeof setInterval> | undefined;
    if (!isIdle && client) {
      GA.event({
        category: "timer",
        action: "start"
      });
      const callHeartbeat = async () => {
        try {
          const session = await client?.mutate<{
            heartbeatSession: SessionType;
          }>({
            mutation: HEARTBEAT,
            variables: {
              page: pageRef.current,
              translatedChannelId: queryRef.current.translatedChannelID,
              translatedVideoId: queryRef.current.translatedVideoID
            }
          });
          if (session?.data?.heartbeatSession) {
            setSession(session.data.heartbeatSession);
            GA.setDimensions({
              sessionID: session.data.heartbeatSession.id as string
            });
            setHeartbeatSuccess(true);
            setLastHeartbeat(new Date());
          }
        } catch {
          setHeartbeatSuccess(false);
        }
      };
      ch = setInterval(callHeartbeat, 10000);
      // Call right away on the first load
      // If user keeps refreshing the page to try to game, binder will always return the last session without updating it
      callHeartbeat();
    }

    return () => {
      if (ch) {
        clearInterval(ch);
        GA.event({
          category: "timer",
          action: "idle"
        });
      }
    };
  }, [isIdle, client]);

  useEffect(() => {
    if (session?.totalTime) {
      setTime(session?.totalTime);
    }
    let i: ReturnType<typeof setInterval> | undefined;
    if (!isIdle) {
      i = setInterval(() => {
        setTime(t => t + 1);
      }, 1000);
    }

    return () => {
      if (i) {
        clearInterval(i);
      }
    };
  }, [session?.totalTime, isIdle]);

  return (
    <SessionContext.Provider
      value={{
        lastHeartbeat,
        heartbeatSuccess,
        isIdle,
        totalTime: time
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
