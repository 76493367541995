import { useRef } from "react";
import { GA } from "../../analytics/ga";
import { TimingArgs } from "react-ga";

export const useGATimer = () => {
  const startTimeRef = useRef<number | null>();

  function startTimer() {
    const time = Math.round(performance.now());
    startTimeRef.current = time; // If use state the end function gets regenerated, but where ever it is
    // used, it will retain the previous function
    return time;
  }
  function endTimer(timingArgs: Omit<TimingArgs, "value">) {
    if (startTimeRef.current) {
      GA.timing({
        ...timingArgs,
        value: Math.round(performance.now()) - startTimeRef.current
      });
      startTimeRef.current = null;
    }
  }
  return { startTimer, endTimer };
};
